
import { Vue, Component } from "vue-property-decorator";
import { BlogService } from "@/api/BlogServiceApi";
import { IBlogEntryView } from "@/models/BlogEntry";
import {
  GetLocale,
  GetStartingLocale,
  GetSupportedLocales,
  SetLocale
} from "@/utility/LanguageHelper";
import QuotationCategory from "@/models/QuotationCategory";
import { QuotationService } from "@/api/QuotationServiceApi";

@Component({
  components: {}
})
export default class BlogMain extends Vue {
  blogEntries: Array<IBlogEntryView> = [];
  categoryOptions = Array<QuotationCategory>();
  lang: string = "";

  async created() {
    this.lang = this.$route.params.lang;
    await BlogService.GetBlogs(this.lang).then(response => {
      this.blogEntries = response.data;
    });

    await QuotationService.GetCategories()
      .then(response => {
        this.categoryOptions = response;
      })
      .catch(() => {});
  }

  blogEntry(quotationCategoryId: number) {
    return this.blogEntries.filter(
      b => b.quotationCategoryId == quotationCategoryId
    );
  }

  redirectLink(url: string) {
    if (this.$router.currentRoute.fullPath.slice(-1) == "/") {
      return url;
    }

    return this.lang + "/" + url;
  }
}
